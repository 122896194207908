import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import thousands from 'thousands';
import { View, Text, Font, Image, StyleSheet } from '@react-pdf/renderer';
import DFPLiHeiBd from '../../assets/fonts/DFPLiHei-Bd.ttf';
import DFPLiHeiMd from '../../assets/fonts/DFPLiHei-Md.ttf';
import DigitalDisplay from '../../assets/fonts/Digital_Display.ttf';
import Impact from '../../assets/fonts/impact.ttf';

Font.register({ family: 'DFPLiHei-Bd', src: DFPLiHeiBd });
Font.register({ family: 'DFPLiHei-Md', src: DFPLiHeiMd });
Font.register({ family: 'Impact', src: Impact });
Font.register({ family: 'Digital Display', src: DigitalDisplay });

const fontSizeScale = 0.75;

const styles = StyleSheet.create({
	container: {
		position: 'relative',
		width: '100mm',
		height: '70mm',
	},
	titleView: {
		position: 'absolute',
		top: '6mm',
		left: 0,
		width: '100%',
		paddingLeft: '5mm',
		paddingRight: '5mm',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
	name: {
		fontFamily: 'DFPLiHei-Bd',
		fontSize: `${17 * fontSizeScale}px`,
	},
	brand: {
		fontFamily: 'DFPLiHei-Bd',
		fontSize: `${13 * fontSizeScale}px`,
		paddingLeft: '2mm',
	},
	description: {
		position: 'absolute',
		top: '12mm',
		left: 0,
		width: '100%',
		paddingLeft: '5mm',
		paddingRight: '5mm',
		fontFamily: 'DFPLiHei-Md',
		fontSize: `${11 * fontSizeScale}px`,
		lineHeight: 1.4,
	},
	spec: {
		position: 'absolute',
		top: '25mm',
		left: 0,
		width: '100%',
		paddingLeft: '5mm',
		paddingRight: '5mm',
		fontFamily: 'DFPLiHei-Bd',
		fontSize: `${11 * fontSizeScale}px`,
	},
	dollarSign: {
		position: 'absolute',
		top: '44mm',
		left: '5mm',
		width: '100%',
		fontFamily: 'Impact',
		fontSize: `${32 * fontSizeScale}px`,
	},
	price: {
		position: 'absolute',
		top: '45mm',
		left: '12mm',
		width: '100%',
		fontFamily: 'Digital Display',
		fontSize: `${90 * fontSizeScale}px`,
	},
	listPrice: {
		position: 'absolute',
		top: '63mm',
		left: 0,
		width: '100%',
		paddingLeft: '5mm',
		paddingRight: '5mm',
		fontFamily: 'DFPLiHei-Md',
		fontSize: `${16 * fontSizeScale}px`,
	},
	qrcode: {
		top: '42.5mm',
		left: '77.5mm',
		width: '20mm',
		height: '20mm',
	},
	webLink: {
		position: 'absolute',
		top: '63mm',
		left: '68.5mm',
		fontFamily: 'DFPLiHei-Md',
		fontSize: `${16 * fontSizeScale}px`,
	},
});

class PriceTag extends PureComponent {
	getQRCode = () => {
		const { product } = this.props;
		return `https://chart.googleapis.com/chart?cht=qr&chs=500x500&chld=L|0&chl=${encodeURIComponent(product.get('url'))}`;
	}

	render() {
		const { product } = this.props;
		return (
			<View style={styles.container}>
				<View style={styles.titleView}>
					<Text style={styles.name}>
						{product.get('name')}
					</Text>
					<Text style={styles.brand}>
						{product.get('brand')}
					</Text>
				</View>
				<Text style={styles.description}>
					{product.get('description')}
				</Text>
				<Text style={styles.spec}>
					{
						product.get('spec').map((s) => (
							`${s.get('label')}${s.get('value')}`
						)).join('　')
					}
				</Text>
				<Text style={styles.dollarSign}>
					$
				</Text>
				<Text style={styles.price}>
					{product.get('price')}
				</Text>
				<Text style={styles.listPrice}>
					建議零售價 ${thousands(product.get('listPrice'))}
				</Text>
				<Image
					style={styles.qrcode}
					src={this.getQRCode()}
				/>
				<Text style={styles.webLink}>
					ahaashop.com.hk
				</Text>
			</View>
		);
	}
}

PriceTag.propTypes = {
	product: PropTypes.instanceOf(Map).isRequired,
};

export default PriceTag;