import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field as BulmaField, Control, Label, Input, Textarea } from 'react-bulma-components/lib/components/form';

class Field extends PureComponent {
	onChangeHandler = (e) => {
		const { id, onChange } = this.props;
		onChange(id, e.target.value);
	}

	render() {
		const { type, label, value } = this.props;
		let Component = Input;
		switch (type) {
		case 'textarea':
			Component = Textarea;
			break;
		default:
			Component = Input;
			break;
		}
		return (
			<BulmaField>
				<Label>{ label }</Label>
				<Control>
					<Component
						value={ value }
						onChange={ this.onChangeHandler }
					/>
				</Control>
			</BulmaField>
		);
	}
}

Field.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	onChange: PropTypes.func.isRequired,
};

export default Field;
