import React, { Fragment, PureComponent } from 'react';
import { View, Image, StyleSheet } from '@react-pdf/renderer';
import ahaaRibbon from '../../assets/images/ahaa-ribbon.png';

const borderBlackStyle = '0.2mm solid #000';

const styles = StyleSheet.create({
	verticalTopLeft: {
		position: 'absolute',
		top: 0,
		left: '4.9mm',
		width: '1mm',
		height: '5mm',
		borderLeft: borderBlackStyle,
	},
	verticalTopCenter: {
		position: 'absolute',
		top: 0,
		left: '104.9mm',
		width: '1mm',
		height: '5mm',
		borderLeft: borderBlackStyle,
	},
	verticalTopRight: {
		position: 'absolute',
		top: 0,
		left: '204.9mm',
		width: '1mm',
		height: '5mm',
		borderLeft: borderBlackStyle,
	},
	verticalBottomLeft: {
		position: 'absolute',
		bottom: 0,
		left: '4.9mm',
		width: '1mm',
		height: '5mm',
		borderLeft: borderBlackStyle,
	},
	verticalBottomCenter: {
		position: 'absolute',
		bottom: 0,
		left: '104.9mm',
		width: '1mm',
		height: '5mm',
		borderLeft: borderBlackStyle,
	},
	verticalBottomRight: {
		position: 'absolute',
		bottom: 0,
		left: '204.9mm',
		width: '1mm',
		height: '5mm',
		borderLeft: borderBlackStyle,
	},
	horizontalTopLeft: {
		position: 'absolute',
		top: '8.5mm',
		left: 0,
		width: '2.75mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalTopMiddle1: {
		position: 'absolute',
		top: '8.5mm',
		left: '49mm',
		width: '5.5mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalTopMiddle2: {
		position: 'absolute',
		top: '8.5mm',
		left: '149mm',
		width: '5.5mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalTopRight: {
		position: 'absolute',
		top: '8.5mm',
		left: '207.25mm',
		width: '2.75mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalMiddle1Left: {
		position: 'absolute',
		top: '78.5mm',
		left: 0,
		width: '2.75mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalMiddle1Middle1: {
		position: 'absolute',
		top: '78.5mm',
		left: '49mm',
		width: '5.5mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalMiddle1Middle2: {
		position: 'absolute',
		top: '78.5mm',
		left: '149mm',
		width: '5.5mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalMiddle1Right: {
		position: 'absolute',
		top: '78.5mm',
		left: '207.25mm',
		width: '2.75mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalMiddle2Left: {
		position: 'absolute',
		top: '148.5mm',
		left: 0,
		width: '2.75mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalMiddle2Middle1: {
		position: 'absolute',
		top: '148.5mm',
		left: '49mm',
		width: '5.5mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalMiddle2Middle2: {
		position: 'absolute',
		top: '148.5mm',
		left: '149mm',
		width: '5.5mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalMiddle2Right: {
		position: 'absolute',
		top: '148.5mm',
		left: '207.25mm',
		width: '2.75mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalMiddle3Left: {
		position: 'absolute',
		top: '218.5mm',
		left: 0,
		width: '2.75mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalMiddle3Middle1: {
		position: 'absolute',
		top: '218.5mm',
		left: '49mm',
		width: '5.5mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalMiddle3Middle2: {
		position: 'absolute',
		top: '218.5mm',
		left: '149mm',
		width: '5.5mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalMiddle3Right: {
		position: 'absolute',
		top: '218.5mm',
		left: '207.25mm',
		width: '2.75mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalBottomLeft: {
		position: 'absolute',
		bottom: '8.5mm',
		left: 0,
		width: '2.75mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalBottomMiddle1: {
		position: 'absolute',
		bottom: '8.5mm',
		left: '49mm',
		width: '5.5mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalBottomMiddle2: {
		position: 'absolute',
		bottom: '8.5mm',
		left: '149mm',
		width: '5.5mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	horizontalBottomRight: {
		position: 'absolute',
		bottom: '8.5mm',
		left: '207.25mm',
		width: '2.75mm',
		height: '1mm',
		borderTop: borderBlackStyle,
	},
	priceTag1_2: {
		position: 'absolute',
		top: '40mm',
		left: 0,
		width: '210mm',
	},
	priceTag3_4: {
		position: 'absolute',
		top: '110mm',
		left: 0,
		width: '210mm',
	},
	priceTag5_6: {
		position: 'absolute',
		top: '180mm',
		left: 0,
		width: '210mm',
	},
	priceTag7_8: {
		position: 'absolute',
		top: '250mm',
		left: 0,
		width: '210mm',
	},
});

class ReferenceLines extends PureComponent {
	render() {
		return (
			<Fragment>
				<Image src={ ahaaRibbon } style={ styles.priceTag1_2 } />
				<Image src={ ahaaRibbon } style={ styles.priceTag3_4 } />
				<Image src={ ahaaRibbon } style={ styles.priceTag5_6 } />
				<Image src={ ahaaRibbon } style={ styles.priceTag7_8 } />
				<View style={ styles.verticalTopLeft } />
				<View style={ styles.verticalTopCenter } />
				<View style={ styles.verticalTopRight } />
				<View style={ styles.verticalBottomLeft } />
				<View style={ styles.verticalBottomCenter } />
				<View style={ styles.verticalBottomRight } />
				<View style={ styles.horizontalTopLeft } />
				<View style={ styles.horizontalTopMiddle1 } />
				<View style={ styles.horizontalTopMiddle2 } />
				<View style={ styles.horizontalTopRight } />
				<View style={ styles.horizontalMiddle1Left } />
				<View style={ styles.horizontalMiddle1Middle1 } />
				<View style={ styles.horizontalMiddle1Middle2 } />
				<View style={ styles.horizontalMiddle1Right } />
				<View style={ styles.horizontalMiddle2Left } />
				<View style={ styles.horizontalMiddle2Middle1 } />
				<View style={ styles.horizontalMiddle2Middle2 } />
				<View style={ styles.horizontalMiddle2Right } />
				<View style={ styles.horizontalMiddle3Left } />
				<View style={ styles.horizontalMiddle3Middle1 } />
				<View style={ styles.horizontalMiddle3Middle2 } />
				<View style={ styles.horizontalMiddle3Right } />
				<View style={ styles.horizontalBottomLeft } />
				<View style={ styles.horizontalBottomMiddle1 } />
				<View style={ styles.horizontalBottomMiddle2 } />
				<View style={ styles.horizontalBottomRight } />
			</Fragment>
		);
	}
}

export default ReferenceLines;