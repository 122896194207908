import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { pdf } from '@react-pdf/renderer';
import { List } from 'immutable';
import Button from 'react-bulma-components/lib/components/button';
import PDFRenderer from '../pdf-renderer';
import styles from './PDFDownloadLink.module.scss';

async function getShortenedUrl(url) {
  if (url === 'https://') {
    return url;
  }

  const response = await fetch(
    process.env.REACT_APP_YOURLS_API,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        action: 'shorturl',
        url,
        format: 'json',
        signature: process.env.REACT_APP_YOURLS_SIGNATURE_TOKEN,
      }),
      mode: 'cors',
    }
  );
  const responseJson = await response.json();
  return responseJson.shorturl;
}

function PDFDownloadLink({ data }) {
  const [isLoading, setIsLoading] = useState(false);

  const onClick = useCallback(async () => {
    setIsLoading(true);

    let newData = data;

    for (let i = 0; i < newData.size; i++) {
      const shortenedUrl = await getShortenedUrl(newData.get(i).get('url'));
      newData = newData.set(i, newData.get(i).set('url', shortenedUrl));
    }

    const blob = await pdf(<PDFRenderer data={newData} />).toBlob();
    var objectURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.href = objectURL;
    tempLink.setAttribute('download', 'ahaa-pricetag.pdf');
    tempLink.click();

    setIsLoading(false);
  }, [data]);

  return (
    <Button color="primary" rounded className={styles.button} onClick={onClick} disabled={isLoading}>
      Download
    </Button>
  );
}

PDFDownloadLink.defaultProps = {
  data: PropTypes.instanceOf(List).isRequired,
};

export default PDFDownloadLink;

