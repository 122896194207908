import React, { Fragment, PureComponent } from 'react';
import { List, Map } from 'immutable';
import Navbar from 'react-bulma-components/lib/components/navbar';
import Container from 'react-bulma-components/lib/components/container';
import Heading from 'react-bulma-components/lib/components/heading';
import Columns from 'react-bulma-components/lib/components/columns';
import ProductForm from '../product-form';
import PDFDownloadLink from '../pdf-download-link';
import './App.scss';
import styles from './App.module.scss';

class App extends PureComponent {
	constructor() {
		super();
		this.defaultProductMap = new Map(window.location.hash === '#default' ? {
			name: '嵌入式電焗爐 EOBB8857AAX',
			brand: 'Electrolux 伊萊克斯',
			description: 'UltraFan Plus均勻受熱，InfiSpaceTM特大內壁，蒸煮功能，\n風扇輔助熱循環，多層防燙璃門，蒸汽清潔，德國製造',
			spec: new List([
				new Map({ label: '容量：', value: '74L', }),
				new Map({ label: '保養期：', value: '2年', }),
				new Map({ label: '尺寸：', value: 'W560 X D550 X H590 (mm)', }),
			]),
			price: 29800,
			listPrice: 29800,
			url: 'https://www.wavenex.com.hk/',
		} : {
			name: '',
			brand: '',
			description: '',
			spec: new List([
				new Map({ label: '容量：', value: '', }),
				new Map({ label: '保養期：', value: '', }),
				new Map({ label: '尺寸：', value: '', }),
			]),
			price: 0,
			listPrice: 0,
			url: 'https://',
		});
		this.state = {
			isFetching: false,
			form: new List([
				this.defaultProductMap,
				this.defaultProductMap,
				this.defaultProductMap,
				this.defaultProductMap,
				this.defaultProductMap,
				this.defaultProductMap,
				this.defaultProductMap,
				this.defaultProductMap,
			]),
			// ].map((product, index) => product.set('url', product.get('url') + `?${index + 1}`))),
		};
	}

	componentDidMount() {
		window.addEventListener('beforeunload', function (e) {
			e.preventDefault();
			e.returnValue = '';
		});
	}

	onChangeProductHandler = (index, product) => {
		const { form } = this.state;
		this.setState({
			form: form.set(index, product),
		});
	}

	renderProduct = (product, index) => {
		return (
			<Columns.Column
				key={`product-form-${index}`}
				size={6}
			>
				<ProductForm
					index={index}
					form={product}
					onChange={this.onChangeProductHandler}
				/>
			</Columns.Column>
		);
	}

	render() {
		const { form } = this.state;
		return (
			<Fragment>
				<Navbar
					fixed="top"
					active
					transparent
					className={styles.navbar}
				>
					<Container fluid className={styles.container}>
						<Heading>
							Ahaa Price Tag PDF Generator
							<small>Powered by Wavenex</small>
						</Heading>
						<PDFDownloadLink data={form} />
					</Container>
				</Navbar>

				<Container fluid className={styles.app}>
					<Columns>
						{form.map(this.renderProduct)}
					</Columns>
				</Container>
			</Fragment>
		);
	}
}

export default App;
