import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'react-bulma-components/lib/components/form';

class SpecField extends PureComponent {
	onChangeHandler = (e) => {
		const { index, id, onChange } = this.props;
		onChange(index, id, e.target.value);
	}

	render() {
		const { value } = this.props;
		return (
			<Input
				value={ value }
				onChange={ this.onChangeHandler }
			/>
		);
	}
}

SpecField.propTypes = {
	index: PropTypes.number.isRequired,
	id: PropTypes.string.isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};

export default SpecField;