import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Map } from 'immutable';
import Heading from 'react-bulma-components/lib/components/heading';
import Field from '../field';
import styles from './ProductForm.module.scss';
import SpecFields from '../spec-fields';

class ProductForm extends PureComponent {
	constructor() {
		super();
		this.fields = [
			{ id: 'name', label: 'Name', type: 'text' },
			{ id: 'brand', label: 'Brand', type: 'text' },
			{ id: 'description', label: 'Description', type: 'textarea' },
			{ id: 'spec', label: 'Spec', type: 'spec' },
			{ id: 'price', label: 'Price', type: 'number' },
			{ id: 'listPrice', label: 'List Price', type: 'number' },
			{ id: 'url', label: 'URL to QR Code', type: 'text' },
		];
	}

	onChangeHandler = (id, value) => {
		const { index, form, onChange } = this.props;
		onChange(index, form.set(id, value));
	}

	renderField = (field) => {
		const { form } = this.props;
		if (field.type === 'spec') {
			return (
				<SpecFields
					key={ field.id }
					id={ field.id }
					label={ field.label }
					values={ form.get(field.id) }
					onChange={ this.onChangeHandler }
				/>
			);
		}
		return (
			<Field
				key={ field.id }
				id={ field.id }
				label={ field.label }
				type={ field.type }
				value={ form.get(field.id) }
				onChange={ this.onChangeHandler }
			/>
		);
	}

	render() {
		const { index } = this.props;
		return (
			<div className={ classnames(styles.productForm, (index % 4 === 0 || index % 4 === 3) && styles.alternative) }>
				<Heading><u>Product #{ index + 1 }</u></Heading>
				{ this.fields.map(this.renderField) }
			</div>
		);
	}
}

ProductForm.propTypes = {
	index: PropTypes.number.isRequired,
	form: PropTypes.instanceOf(Map).isRequired,
	onChange: PropTypes.func.isRequired,
};

export default ProductForm;
