import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Page, View, Document, StyleSheet } from '@react-pdf/renderer';
import ReferenceLines from './ReferenceLines';
import PriceTag from './PriceTag';

const styles = StyleSheet.create({
	page: {
		flexDirection: 'row',
		backgroundColor: '#fff'
	},
	priceTags: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'row',
		alignContent: 'flex-start',
		flexWrap: 'wrap',
		padding: 0,
		margin: 0,
		left: '5mm',
		top: '8.5mm',
		width: '200mm',
		height: '280mm',
	},
});

class PDFRenderer extends PureComponent {
	renderProduct = (product, index) => {
		return (
			<PriceTag
				key={ `price-tag-${index}` }
				product={ product }
			/>
		)
	}

	render() {
		const { data } = this.props;
		return (
			<Document>
				<Page size="A4" style={ styles.page }>
					<View style={ styles.priceTags }>
						{ data.map(this.renderProduct) }
					</View>
					<ReferenceLines />
				</Page>
			</Document>
		);
	}
}

PDFRenderer.propTypes = {
	data: PropTypes.instanceOf(List).isRequired,
};

export default PDFRenderer;