import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Field, Control, Label } from 'react-bulma-components/lib/components/form';
import Columns from 'react-bulma-components/lib/components/columns';
import SpecField from './SpecField';

class SpecFields extends PureComponent {
	onChangeHandler = (index, specFieldId, value) => {
		const { id, values, onChange } = this.props;
		onChange(
			id,
			values.set(
				index,
				values.get(index).set(specFieldId, value)
			)
		);
	}

	renderValue = (value, index) => {
		return (
			<Fragment key={ `spec-fields-${index}` }>
				<Columns.Column size={ 4 }>
					<SpecField
						index={ index }
						id="label"
						value={ value.get('label') }
						onChange={ this.onChangeHandler }
					/>
				</Columns.Column>
				<Columns.Column size={ 8 }>
					<SpecField
						index={ index }
						id="value"
						value={ value.get('value') }
						onChange={ this.onChangeHandler }
					/>
				</Columns.Column>
			</Fragment>
		);
	}

	render() {
		const { label, values } = this.props;
		return (
			<Field>
				<Label>{ label }</Label>
				<Control>
					<Columns gapless>
						{ values.map(this.renderValue) }
					</Columns>
				</Control>
			</Field>
		);
	}
}

SpecFields.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	values: PropTypes.instanceOf(List).isRequired,
	onChange: PropTypes.func.isRequired,
};

export default SpecFields;